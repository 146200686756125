<!--优惠券管理-->
<template>
  <div class="mainBox">
    <!--场景搜索-->
    <div class="selectionBar">
      <div class="flex flex-ai-c flex-jc-sb flex-wrap">
        <label>场景：</label>
        <el-button type="primary" block size="mini" @click="edit()">添加优惠券</el-button>
      </div>
    </div>
    <!--主要内容-->
    <div class="table" style="height:calc( 100% - 175px )">
      <el-table
        :data="list"
        stripe
        :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
        @sort-change="getList()"
        height="calc( 100% - 41px )"
      >
        <el-table-column prop="discount_name" label="优惠券名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type_text" label="优惠券类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="full" label="满够金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reduce" label="优惠金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color:#67C23A">已开启</span>
            <span v-else style="color:#F56C6C">已停用</span>
          </template>
        </el-table-column>
        <el-table-column prop="use_start_time" label="使用时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.use_start_time }} 至 {{scope.row.use_end_time}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="240">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setCouponStatus(scope.row)"
            >{{scope.row.status == 1 ? '停用' : '启用'}}</el-button>
            <el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="curr"
          :page-size="row"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
        ></el-pagination>
      </div>
    </div>

    <!-- 新建框 -->
    <el-dialog
      :title="title"
      custom-class="way"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="550px"
    >
      <el-form ref="formAuth" :rules="formRules" label-width="110px" :model="formData" size="small">
        <el-form-item label="优惠券类型" prop="type">
          <el-select v-model="formData.type" placeholder="选择优惠券类型" clearable style="width:100%">
            <el-option
              v-for="item in selectData"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品选择" prop="cate_id" v-if="formData.type==3">
          <el-select v-model="formData.cate_id" placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in catelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券名称" prop="discount_name">
          <el-input v-model="formData.discount_name" placeholder="优惠券名称" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="满" prop="full">
          <el-col :span="9">
            <el-input
              v-model="formData.full"
              autocomplete="off"
              placeholder="满够金额"
              oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)"
            ></el-input>
          </el-col>
          <el-col class="line" :span="3" :offset="1">元，减</el-col>
          <el-col :span="9">
            <el-input
              v-model="formData.reduce"
              autocomplete="off"
              placeholder="优惠金额"
              oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)"
            ></el-input>
          </el-col>
          <el-col class="line" :span="1" :offset="1">元</el-col>
        </el-form-item>
        <el-form-item label="优惠券使用时间" style="width:100%">
          <el-date-picker
            v-model="formData.use_time"
            value-format="yyyy-MM-dd"
            type="daterange"
            style="width:100%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="每人限领数量">
          <el-input v-model="formData.use_limit" placeholder="每人限领数量" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="优惠券数量">
          <el-input v-model="formData.sum" placeholder="优惠券数量" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  couponListApi,
  couponSaveApi,
  couponDeleteApi,
  couponSetupApi,
} from '@/api/config.js'
import { shopListApi } from '@/api/store.js'
export default {
  data() {
    return {
      formRules: {
        type: [
          {
            required: true,
            message: '请选择优惠券类型',
            trigger: 'blur',
          },
        ],
        discount_name: [
          {
            required: true,
            message: '请输入优惠券名称',
            trigger: 'blur',
          },
        ],
        full: [
          {
            required: true,
            message: '请输入优惠券金额',
            trigger: 'blur',
          },
        ],
        cate_id: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur',
          },
        ],
      },
      formData: {
        cate_id: 0,
      }, //详情数据
      selectData: [
        {
          id: 1,
          name: '新人券',
        },
        {
          id: 2,
          name: '商品券',
        },
        {
          id: 3,
          name: '充值商品券',
        },
      ], //下拉选择列表
      list: [], //优惠券列表

      title: '添加优惠券',
      curr: 1, //页码
      row: 10, //每页条数
      pages: 1, //总页数
      count: 0, //总条数

      dialogFormVisible: false, //弹出框状态

      catelist: [],
    }
  },
  mounted() {
    this.getList()
    this.selectList2()
  },
  methods: {
    //优惠券列表
    getList: function () {
      couponListApi({
        row: this.row,
        curr: this.curr,
      }).then((response) => {
        this.list = response.list
        this.curr = response.curr
        this.pages = response.pages
        this.count = response.count
      })
    },
    //账号信息更改
    edit: function (param = {}) {
      this.dialogFormVisible = true
      if (typeof param.id == 'undefined') {
        this.title = '添加优惠券'
        this.formData = {}
      } else {
        this.title = '编辑优惠券'
        this.formData = JSON.parse(JSON.stringify(param))
      }
    },

    //删除优惠券
    deletes: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          couponDeleteApi({
            id: id,
          }).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '已删除!',
            })
          })
        })
        .catch(() => {})
    },
    //设置优惠券状态
    setCouponStatus: function (param) {
      let text =
        param.status == 1
          ? '此操作将停用优惠券, 是否继续'
          : '此操作将开启优惠券, 是否继续'
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        couponSetupApi({
          id: param.id,
          status: param.status == 1 ? 0 : 1,
        }).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '设置成功!',
          })
        })
      })
    },
    closeDialog: function () {
      this.$refs['formAuth'].resetFields()
      this.dialogFormVisible = false
    },
    submit: function () {
      this.$refs['formAuth'].validate((valid) => {
        if (valid) {
          couponSaveApi(this.formData).then(() => {
            this.closeDialog()
            this.getList()
            this.$message({
              type: 'success',
              message: '保存成功!',
            })
          })
        }
      })
    },
    //翻页
    currentChange: function (curr) {
      this.curr = curr
      this.getList()
    },
    //改变每页条数
    sizeChange: function (row) {
      this.row = row
      this.getList()
    },

    //下拉列表
    selectList2: function () {
      shopListApi({ row: 100, series_id: 38 }).then((response) => {
        console.log('response', response)
        this.catelist = response.list
        console.log('this.catelist', this.catelist)
      })
    },
  },
}
</script>